.main{
    padding: 1%;
    border-bottom: 1px solid black;
    display: flex;
}

.leftDiv{
    display: flex;
    width: 30%;
}

.rightDiv{
    width: 10%;
}

.logoutBtn{
    border: none;
    padding: 5%;
    font-weight: bold;
    cursor: pointer;
}

.logoutBtn:hover{
    background-color: rgb(142, 145, 145);
}


.headerButtons{
    margin-right: 2%;
    cursor: pointer;
}