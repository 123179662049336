.header{
    margin: 1%;
    width: 98%;
    height: 5vh;
}

.addButton{
    color: white;
    font-weight: bold;
    background-color: green;
    padding: 1%;
    float: right;
    cursor: pointer;
}

.addBox{
    padding: 1%;
    border: 1px solid black;
    width: 40%;
    margin: 0 auto;
    text-align: center;
}

.addSubBox{
    margin-bottom: 1%;
}

.addBox .addButton{
    float: none;
    width: 15%;
    margin: 2% auto;
}

table{
    margin: 5% auto;
    border: 1px solid black;
    width: 80%;
}

td{
    padding: 1%;
    margin: 1%;
    border: 1px solid grey;
    width: 1%;
    white-space: nowrap;
    text-align: center;
}

th{
    border: 1px solid black;
    padding: 1%;
    width: 1%;
    white-space: nowrap;
}


img{
    width: 30%;
    height: 30%;
    margin: 2%;
}

.imageColumn{
    overflow-y: auto;
}

.deleteBtn{
    padding: 5%;
    border: none;
    color: white;
    background-color: red;
    font-weight: bold;
    cursor: pointer;
}

.editBtn{
    padding: 5%;
    border: none;
    color: white;
    background-color: green;
    font-weight: bold;
    cursor: pointer;
}

.updateBtn{
    padding: 5%;
    border: none;
    color: white;
    background-color: green;
    font-weight: bold;
    cursor: pointer;
}

input{
    text-align: center;
    padding: 2%;
}

textarea{
    text-align: center;
    padding: 2%;
}